// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-workshop-image-index-js": () => import("/opt/build/repo/src/templates/WorkshopImage/index.js" /* webpackChunkName: "component---src-templates-workshop-image-index-js" */),
  "component---src-templates-workshop-js": () => import("/opt/build/repo/src/templates/Workshop.js" /* webpackChunkName: "component---src-templates-workshop-js" */),
  "component---src-templates-workshop-printable-index-js": () => import("/opt/build/repo/src/templates/WorkshopPrintable/index.js" /* webpackChunkName: "component---src-templates-workshop-printable-index-js" */),
  "component---src-templates-workshop-type-js": () => import("/opt/build/repo/src/templates/WorkshopType.js" /* webpackChunkName: "component---src-templates-workshop-type-js" */),
  "component---src-templates-workshop-type-printable-index-js": () => import("/opt/build/repo/src/templates/WorkshopTypePrintable/index.js" /* webpackChunkName: "component---src-templates-workshop-type-printable-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

